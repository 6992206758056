import { keyframes, styled } from "styled-components";
import bushSprite from "../../assets/loading-elements/2.png";
import logoSprite from "../../assets/loading-elements/1.png";
import henSprite from "../../assets/loading-elements/hen.png";
import roosterSprite from "../../assets/loading-elements/rooster.png";
import starsSprite from "../../assets/loading-elements/4.png";
import backgroundSprite from "../../assets/loading-elements/bg-splash.png";
import { useEffect } from "react";
import { PlayAudio } from "@lindar-joy/goosicorn-quickies-library";

export const ScaleIn = keyframes`
    0% { transform: scale(0) }
    100% { transform: scale(1) }
`;

export const StarsAnimation = keyframes`
    0% { opacity: 0.5 }
    100% { opacity: 1 }
`;

const Background = styled.div`
    background-image: url(${backgroundSprite});
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
`;

const StyledSplash = styled.div`
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 600;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Sprite = styled.div`
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 1;
`;

const Bush = styled(Sprite)`
    background-image: url(${bushSprite});
    width: 100%;
    max-width: 500px;
    animation: ${ScaleIn} 500ms cubic-bezier(.81,1.02,.45,1.66);
    transform: scale(0);
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 500px;
`;

const Logo = styled(Sprite)`
    background-image: url(${logoSprite});
    width: 100%;
    max-width: 500px;
    position: absolute;
    animation: ${ScaleIn} 500ms cubic-bezier(.81,1.02,.45,1.66);
    animation-delay: 1200ms;
    transform: scale(0);
    animation-fill-mode: forwards;
    top: 0;
`;

const Hen = styled(Sprite)`
    background-image: url(${henSprite});
    width: 50%;
    max-width: 250px;
    position: absolute;
    transform: translate(-100px, 50%);
    top: 0;
`;

const Rooster = styled(Sprite)`
    background-image: url(${roosterSprite});
    width: 50%;
    max-width: 250px;
    position: absolute;
    transform: translate(100px, 50%);
    top: 0;
    right: 0;
`;

const Stars = styled(Sprite)`
    background-image: url(${starsSprite});
    width: 100%;
    max-width: 500px;
    position: absolute;
    animation: ${StarsAnimation} 500ms infinite alternate;
    animation-delay: 2000ms;
    top: 0;
    right: 0;
    opacity: 0;
`;

const Splash: React.FC<SplashProps> = ({

    ...props
}) => {

    return (
        <StyledSplash>
            <Background />
            <Container>
                <Bush />
                <Hen />
                <Logo />
                <Rooster />
                <Stars />
            </Container>
        </StyledSplash>
    )
};

interface SplashProps {
    
}

export default Splash;