

export const GameRules =
<div>
    <h3>Gems</h3>
    <p><strong>RTP:</strong> 95%</p>
    <em>Spin the eggs to match up the gems with the prize table.</em>
    <p>Click/tap the play button to bet. If you match your result to an entry in the prize table, you win!</p>
    <p>The prizes are as follows:</p>
    <ul>
        <li>A pair (x1.00 bet amount)</li>
        <li>Two pairs (x1.40 bet amount)</li>
        <li>Three of a kind (x2.00 bet amount)</li>
        <li>A full house (x3.00 bet amount)</li>
        <li>Four of a kind (x6.00 bet amount)</li>
        <li>Five of a kind (x10.00 bet amount)</li>
    </ul>

    <h3>Game rules</h3>
    <ul>
        <li>To change the bet amount, press the bet button in the HUD on the bottom and select the desired bet amount from the bet amount popup</li>
        <li>To place a bet, press the PLAY button</li>
        <li>Prizes won will highlight in the prize table area underneath the gems panel</li>
    </ul>
</div>
