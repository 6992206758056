import React, { useEffect, useRef, useState } from "react";

import { SpineCanvas, SpineStage, SpineStageRef, Timeout } from "@lindar-joy/goosicorn-quickies-library";
import { styled } from "styled-components";
import { BlueBackground, BluePill, CardContainer, DefaultBackground, EggContainer, Frame, GreenBackground, GreenPill, OrangeBackground, OrangePill, PurpleBackground, PurplePill, RedBackground, RedPill, SymbolsContainer, TurquoiseBackground, TurquoisePill, YellowBackground, YellowPill } from "../../styles";
import anime from "animejs";
const gems = [
    "blue",
    "green",
    "orange",
    "purple",
    "red",
    "turquoise",
    "yellow"
]

export const symbolMap = {
    S1: "blue",
    S2: "green",
    S3: "orange",
    S4: "purple",
    S5: "red",
    S6: "turquoise",
    S7: "yellow"
}

const StyledStage = styled(SpineStage)`
    position: absolute;
    aspect-ratio: 0.17;
    height: 168px;

    

    @media (max-width: 599px) {
        transform: scale(0.62);
        transform-origin: top left;
    }

    @media (orientation: portrait) and (max-width: 374px) {
        transform: scale(0.455);
        transform-origin: top left;
    }

    @media (orientation: landscape) and (max-height: 899px) {
        transform: scale(0.745);
        transform-origin: top left;
    }

    @media (orientation: landscape) and (max-height: 680px) {
        transform: scale(0.458);
        transform-origin: top left;
    }
`;

const Panel: React.FC<PanelProps> = ({
    panel,
    starting,
    state,
    wins,
    ...props
}) => {

    const gemsStageRef = useRef<SpineStageRef>(null);
    const eggsStageRef = useRef<SpineStageRef>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    
    const wrapperRef = useRef<HTMLDivElement>(null);

    function getGemSpineObject() {
        const gemSpines: any = [];
        for (let i = 0; i < 6; i++) {
            for (let j = 0; j < gems.length; j++) {
                gemSpines.push({
                    path: `assets/spine/gems-${gems[j]}-win`,
                    id: `gem-${i}-${gems[j]}`,
                    scale: {
                        x: 0.27 * devicePixelRatio,
                        y: 0.27 * devicePixelRatio
                    },
                    x: (59 + i * 122) * devicePixelRatio,
                    y: -80 * devicePixelRatio
                });
            }
        }
        return gemSpines;
    }

    function getEggSpineObject() {
        const eggs: any = [];
        for (let i = 0; i < 6; i++) {
            eggs.push({
                path: `assets/spine/skeleton`,
                id: `egg-${i}`,
                scale: {
                    x: 0.4 * devicePixelRatio,
                    y: 0.4 * devicePixelRatio
                },
                x: (59 + i * 122) * devicePixelRatio,
                y: -80 * devicePixelRatio
            });
        }
        return eggs;
    }


    function getGemSpineStage() {
        //@ts-ignore
        return <StyledStage height={300} width={606} stageId="gems" spineObjects={getGemSpineObject()} onLoad={() => setLoaded(true)} visible={true} ref={gemsStageRef}></StyledStage>
    }

    function getEggSpineStage() {
        //@ts-ignore
        return <StyledStage height={300} width={606} stageId="eggs" spineObjects={getEggSpineObject()} visible={true} ref={eggsStageRef}></StyledStage>
    }

    useEffect(() => {
        if (loaded) {
            const stage = gemsStageRef.current as SpineStageRef;
            const eggsStage = eggsStageRef.current as SpineStageRef;

            switch(state) {
                case "initial":
                    for (let i = 0; i < 6; i++) {
                        eggsStage.setVisible(`egg-${i}`, false);
                        for (let j = 0; j < gems.length; j++) {
                            stage.setVisible(`gem-${i}-${gems[j]}`, false);
                        }
                    }
        
                    panel.forEach( ( symbol, index ) => {
                        const colour = symbolMap[symbol];
                        stage.setVisible(`gem-${index}-${colour}`, true);
                        stage.play(`gem-${index}-${colour}`, `gems-${colour}-win-static`, true);
                    });
                break;

                case "hide":
                    panel.forEach( ( symbol, index ) => {
                        const colour = symbolMap[symbol];
                        const skeleton = stage.getSkeleton(`gem-${index}-${colour}`);
                        const eggSkeleton = stage.getSkeleton(`egg-${index}`);
                        skeleton.x = (59 + index * 122) * devicePixelRatio;
                        anime({
                            targets: skeleton,
                            y: -250  * devicePixelRatio,
                            easing: "easeInQuad",
                            duration: 330
                        });
                        eggsStage.play(`egg-${index}`, "egg-idle", false);
                        eggsStage.setVisible(`egg-${index}`, true);
                        eggsStage.position(`egg-${index}`, (59 + index * 122) * devicePixelRatio, 250 * devicePixelRatio);
                        anime({
                            targets: eggSkeleton,
                            y: -80 * devicePixelRatio,
                            easing: "easeInQuad",
                            duration: 330
                        });
                        
                        stage.play(`gem-${index}-${colour}`, `gems-${colour}-win-static`, false);
                    });
                break;

                case "reveal":
                    for (let i = 0; i < 6; i++) {
                        for (let j = 0; j < gems.length; j++) {
                            stage.setVisible(`gem-${i}-${gems[j]}`, false);
                        }
                    }
        
                    panel.forEach( ( symbol, index ) => {
                        const colour = symbolMap[symbol];
                        const skeleton = stage.getSkeleton(`gem-${index}-${colour}`)
                        skeleton.x = (59 + index * 122) * devicePixelRatio;
                        skeleton.y = -80 * devicePixelRatio;
                        stage.setVisible(`gem-${index}-${colour}`, true);
                        stage.play(`gem-${index}-${colour}`, `gems-${colour}-win-static`, true);
                        setTimeout(() => {
                            eggsStage.play(`egg-${index}`, "egg-explosion", false);
                        }, 160 * index);
                        
                        if (wins[index]) {
                            ( async () => {
                                await Timeout(1000);
                                await stage.play(`gem-${index}-${colour}`, `gems-${colour}-win-reveal`, false);
                                if (state === "reveal") {
                                    stage.play(`gem-${index}-${colour}`, `gems-${colour}-win-idle`, true);
                                }
                                
                            })();
                        }
                    });
                break;

                case "idle":
                    for (let i = 0; i < 6; i++) {
                        for (let j = 0; j < gems.length; j++) {
                            stage.setVisible(`gem-${i}-${gems[j]}`, false);
                        }
                    }
        
                    panel.forEach( ( symbol, index ) => {
                        const colour = symbolMap[symbol];
                        stage.setVisible(`gem-${index}-${colour}`, true);
                        stage.play(`gem-${index}-${colour}`, `gems-${colour}-win-static`, false);
                    });
                break;
            }
            
        }

    }, [ loaded, panel, state ]);

    return (
        <SymbolsContainer {...props} ref={wrapperRef}>
            {panel && panel.map((symbol, index) => { 
                
                return <div key={index}>
                    <Frame>
                        <DefaultBackground />
                        { symbol === "S1" && <BlueBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        { symbol === "S2" && <GreenBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        { symbol === "S3" && <OrangeBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        { symbol === "S4" && <PurpleBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        { symbol === "S5" && <RedBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        { symbol === "S6" && <TurquoiseBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        { symbol === "S7" && <YellowBackground $fadeOut={ state === "hide" } $reveal={state === "reveal"} $delay={state === "reveal" ? (160 * index + 330) : 0}/> }
                        <CardContainer $animateOut={state==="hide"} $delay={index * 20} id={`gem-${index}`}></CardContainer>
                        <EggContainer $animateIn={state === "hide"} $delay={0} id={`egg-${index}`}></EggContainer>
                    </Frame>
                    {state !== "hide" && symbol === "S1" && wins[index] && <BluePill $delay={1500} /> }
                    {state !== "hide" && symbol === "S2" && wins[index] && <GreenPill $delay={1500} /> }
                    {state !== "hide" && symbol === "S3" && wins[index] && <OrangePill $delay={1500} /> }
                    {state !== "hide" && symbol === "S4" && wins[index] && <PurplePill $delay={1500} /> }
                    {state !== "hide" && symbol === "S5" && wins[index] && <RedPill $delay={1500} /> }
                    {state !== "hide" && symbol === "S6" && wins[index] && <TurquoisePill $delay={1500} /> }
                    {state !== "hide" && symbol === "S7" && wins[index] && <YellowPill $delay={1500} /> }
                </div>
            })}
            { getGemSpineStage() }
            { getEggSpineStage() }
        </SymbolsContainer>
    )
};

Panel.defaultProps = {

}

interface PanelProps {
    panel: string[];
    starting: boolean;
    state: "default" | "hide" | "reveal" | "idle" | "initial" | "show-win";
    wins: boolean[];
}

export default React.memo(Panel, ((prev, next) => prev.state === next.state));