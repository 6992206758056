import { useEffect, useState } from 'react';

import { Game, ICommonConnectionData, LoadAudio, PlayAudio, Timeout, emitGameAction, useBfrStatusListener, useCommonBalanceListener, useCommonConnectionListener, useGameEventListener } from "@lindar-joy/goosicorn-quickies-library";
import "@lindar-joy/goosicorn-quickies-library/lib/css/style.css";
import './App.css';
const pkg = require('../package.json');

import { GameHistoryRenderer } from './GameHistoryRenderer';
import { IBetEvent } from './events/betEvent';
import { IShowEvent } from './events/showEvent';
import { GameRules } from './gameRules';
import {
    Background,
    CharacterContainer,
    HenCharacter,
    Logo,
    RoosterCharacter
} from './styles';

import Panel from './components/panel/Panel';
import PrizeTable from './components/prizeTable/PrizeTable';
import Splash from './components/splash/Splash';

let playCount = 0;
let audioStart = false;

function getGemViewport() {
    return {
        x: -250,
        y: -250,
        width: 500,
        height: 500
    }
}

let connectionData: ICommonConnectionData;

function App() {
    
    let [loading, setLoading] = useState<boolean>(true);
    let [betAmount, setBetAmount] = useState<number>(0);
    let [bets, setBets] = useState<number[]>([10, 50, 100, 500, 1000]);
    let [sessionWinnings, setSessionWinnings] = useState<number>(0);
    let [sessionLosses, setSessionLosses] = useState<number>(0);
    let [freePlay, setFreePlay] = useState<boolean>(false);
    let [prize, setPrize] = useState<string>();
    let [reveal, setReveal] = useState<boolean>(false);
    let [panel, setPanel] = useState<string[]>([]);
    let [wins, setWins] = useState<boolean[]>([]);
    let [prizeTable, setPrizeTable] = useState<{[ key: string ]: number}>();
    let [spinInProgress, setSpinInProgress] = useState<boolean>(false);
    let [spinWait, setSpinWait] = useState<boolean>(false);
    let [initialising, setInitialising] = useState<boolean>(true);
    let [ending, setEnding] = useState<boolean>(false);
    let [balance, setBalance] = useState<number>(0);
    let [balanceBacking, setBalanceBacking] = useState<number>(0);
    let [result, setResult] = useState<{first: string; second: boolean;}[]>();
    let [bfrInUse, setBfrInUse] = useState<boolean>(false);
    let [bfrCount, setBfrCount] = useState<number>(0);
    let [showGame, setShowGame] = useState<boolean>(false);

    function getPanelState() {

        if (initialising) {
            return "default";
        }

        if (reveal) {
            return "reveal";
        }
        
        if (spinInProgress) {
            return "hide";
        }
        
        return "initial";
    }

    useBfrStatusListener(bfrStatus => {
        setBfrInUse(bfrStatus.inUse);
        setBfrCount(bfrStatus.bfrCount);
    });

    useCommonBalanceListener( commonBalanceData => {
        setBalanceBacking(commonBalanceData.amount);
    });

    useCommonConnectionListener( connection => {
        connectionData = connection;
    });

    useEffect(() => {

        (async () => {
            await LoadAudio([
                "music", "sfx"
            ]);
            window.addEventListener("pointerdown", async() => {
                if (!audioStart) {
                    audioStart = true;
                    PlayAudio("Hens' Gems Splash", false, 0.6);
                    await Timeout(3400);
                    PlayAudio("Hen's Gems", true, 0.7);
                }
            });
            setTimeout(() => setLoading(false), 1000);
            setTimeout(() => {
                
                setShowGame(true);
            }, 3300);
            
        })();
    }, []);

    useGameEventListener((event) => {
        switch (event.type) {
            case "gems:show":
                const showEvent = event as IShowEvent;
                setResult(showEvent.lastPanel);
                if (showEvent.lastPanel.length > 0) {
                    setPanel(showEvent.lastPanel.map( panelEntry => panelEntry.first));
                } else {
                    setPanel([
                        "S1",
                        "S2",
                        "S3",
                        "S4",
                        "S5"
                    ].sort((a, b) => Math.random() > 0.5 ? 1 : -1));
                }
                setPrizeTable(showEvent.prizeTable);
                setBetAmount(showEvent.bet);
                setPrize(showEvent.lastPrizeName);
                setBalance(balanceBacking);
                setInitialising(false);
            break;
            case "gems:bet":
                PlayAudio("Eggs");
                setEnding(true);
                setTimeout(() => {
                    const betEvent = event as IBetEvent;
                    setBetAmount(betEvent.bet);
                    setPanel(betEvent.result.map( panelEntry => panelEntry.first));
                    setWins(betEvent.result.map( panelEntry => panelEntry.second));
                    setResult(betEvent.result);
                    setTimeout(() => {
                        
                        setReveal(true);
                        setSpinInProgress(false);
                        setPrize(betEvent.prizeName);

                        
                        setTimeout(() => {
                            switch(betEvent.prizeName) {
                                case "ONE_PAIR": break;
                                case "TWO_PAIRS":
                                    PlayAudio(`Gems 2${Math.random() > 0.85 ? " (Chicken)": ""}`);
                                break;
                                case "THREE_OF_A_KIND":
                                    PlayAudio(`Gems 3${Math.random() > 0.85 ? " (Chicken)": ""}`);
                                break;
                                case "FULL_HOUSE":
                                    PlayAudio(`Gems 4${Math.random() > 0.85 ? " (Chicken)": ""}`);
                                break;
                                case "FOUR_OF_A_KIND":
                                    PlayAudio(`Gems 5${Math.random() > 0.85 ? " (Chicken)": ""}`);
                                break;
                                case "FIVE_OF_A_KIND":
                                    PlayAudio(`Gems 6${Math.random() > 0.85 ? " (Chicken)": ""}`);
                                break;
                            }
                            if (!freePlay) {
                                setSessionLosses(sessionLosses + betAmount);
                            }
                            setSessionWinnings(sessionWinnings + betEvent.winnings);
                            setBalance(balanceBacking);
                            setEnding(false);
                        }, 1000);
                    }, 750);
                }, 1000);
            break;
        }
    });

    const playText = () => {
        return bfrInUse ? `PLAY (${bfrCount} Free Plays)` : "PLAY";
    }

    return (
        <div>
            <Background />
            { !loading && <Game
                    gameHistoryDetailView={GameHistoryRenderer}
                    balance={balance}
                    gameName="Gems"
                    bets={bets}
                    bet={betAmount || 0}
                    rightSideHUD={
                        <div></div>
                    }
                    gameVersion={`v${pkg.version}`}
                    gameAreaWidth={ "100%" }
                    gameAreaAutoScale={ false }
                    winnings={ sessionWinnings - sessionLosses }
                    gameRulesContent={GameRules}
                    playText={playText()}
                    showPlayIcon={true}
                    disableBetChange={false}
                    disablePlay={ spinWait }
                    disableHUD={ spinInProgress || ending || initialising}
                    supportedOrientations={["DESKTOP_LANDSCAPE", "DESKTOP_PORTRAIT", "MOBILE_PORTRAIT"]}
                    onBetSelect={ bet => {
                        setBetAmount(bet);
                    }}

                    onPlay={ async usingBFRBalance => {
                        setSpinWait(true);
                        playCount++;
                        setReveal(false);
                        setSpinInProgress(true);
                        if (!usingBFRBalance) {
                            setBalance(balanceBacking - betAmount);
                        }
                        
                        setFreePlay(usingBFRBalance);
                        
                        emitGameAction({
                            type: 'gems:bet',
                            body: {
                                bet: betAmount
                            }
                        })

                        await Timeout(3800);
                        setSpinWait(false);
                    }}
                >
                    <div style={{display: "flex", borderRadius: "12px",  padding: "4px", width: "calc(100% - 10px)", marginLeft: "5px", marginRight: "5px", marginTop: "10px", justifyContent: "space-evenly", backgroundColor: "var(--neutral-15)"}}>
                        <Logo />
                    </div>
                    <Panel panel={panel} wins={wins} starting={spinInProgress} state={getPanelState()}/>
                    { betAmount && prizeTable && <PrizeTable connectionData={ connectionData } bet={betAmount} prizeTable={prizeTable} hideWin={spinInProgress || ending} result={result} prize={prize}/>}
                </Game>
            }

            <CharacterContainer>
                <RoosterCharacter />
                <HenCharacter />
            </CharacterContainer>
            
            { !showGame && <Splash />}
        </div>
        
    );
}

export default App;
