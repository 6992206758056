import styled from "styled-components";

import { GameHistoryRow } from "@lindar-joy/goosicorn-quickies-library";

import BlueGem from "./assets/gems-symbol-3.png";
import GreenGem from "./assets/gems-symbol-4.png";
import OrangeGem from "./assets/gems-symbol-2.png";
import PurpleGem from "./assets/gems-symbol-1.png";
import RedGem from "./assets/gems-symbol-7.png";
import TurquoiseGem from "./assets/gems-symbol-6.png";
import YellowGem from "./assets/gems-symbol-5.png";

interface IGemsGameRoundDetails {
    bet: number;
    lastPanel:{ first: string, second: boolean }[];
    lastPrize: string;
    stateStack: string[];
    gameRoundId: string;
}

const SymbolImage = styled.img`
    width: 50px;
    aspect-ratio: 1;
`;

const SymbolContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;


export const symbolMap = {
    S1: BlueGem,
    S2: GreenGem,
    S3: OrangeGem,
    S4: PurpleGem,
    S5: RedGem,
    S6: TurquoiseGem,
    S7: YellowGem
}

export function renderPanel(panel: string[], starting: boolean, stopping: boolean) {
    
    return <SymbolContainer>
        {panel.map((symbol, index) => (
            (
                <div key={index}>
                    <SymbolImage src={symbolMap[symbol]} key={index}/>
                </div>
            )
        ))}
    </SymbolContainer>;
}


const PrizeMap = {
    ONE_PAIR: "A PAIR",
    TWO_PAIRS: "TWO PAIR",
    THREE_OF_A_KIND: "THREE OF A KIND",
    FULL_HOUSE: "FULL HOUSE",
    FOUR_OF_A_KIND: "FOUR OF A KIND",
    FIVE_OF_A_KIND: "FIVE OF A KIND"
}

export const GameHistoryRenderer = (gameRound: IGemsGameRoundDetails) => {
    console.log(gameRound);
    return <div>
        <div></div>
        { gameRound.lastPrize !== "NO_PRIZE" && <GameHistoryRow><div>Prize type</div><div>{PrizeMap[gameRound.lastPrize]}</div></GameHistoryRow> }
        { renderPanel(gameRound.lastPanel.map( symbol => symbol.first), false, false) }
    </div>;
}
