import { CurrencyFormat, GetCurrencySymbol, ICommonConnectionData, RigNumbers, useCommonConnectionListener } from "@lindar-joy/goosicorn-quickies-library";
import { BlueGemEggIcon, EmptyEggIcon, GreenGemEggIcon, Multiplier, OrangeGemEggIcon, PayoutIcons, PrizeTableContainer, PrizeTableLine, PurpleGemEggIcon, RedGemEggIcon, SolidEggIcon, StrokedEggIcon, TurquoiseGemEggIcon, WinAmount, YellowGemEggIcon } from "../../styles";

function renderPrizeType(prize: string, won?: boolean, result?: { first: string, second: boolean }[]) {
    switch (prize) {
        case "FIVE_OF_A_KIND":
            return <PayoutIcons>
                {won && result && symbolMap[result[0].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[1].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[2].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[3].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[4].first] || <SolidEggIcon /> }
            </PayoutIcons>;
        case "FOUR_OF_A_KIND":
            return <PayoutIcons>
                {won && result && symbolMap[result[0].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[1].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[2].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[3].first] || <SolidEggIcon /> }
                <EmptyEggIcon />
            </PayoutIcons>;
        case "FULL_HOUSE":
            return <PayoutIcons>
                {won && result && symbolMap[result[0].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[1].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[2].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[3].first] || <StrokedEggIcon /> }
                {won && result && symbolMap[result[4].first] || <StrokedEggIcon /> }
            </PayoutIcons>;
        case "THREE_OF_A_KIND":
            return <PayoutIcons>
                {won && result && symbolMap[result[0].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[1].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[2].first] || <SolidEggIcon /> }
                <EmptyEggIcon />
                <EmptyEggIcon />
            </PayoutIcons>;
        case "TWO_PAIRS":
            return <PayoutIcons>
                {won && result && symbolMap[result[0].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[1].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[2].first] || <StrokedEggIcon /> }
                {won && result && symbolMap[result[3].first] || <StrokedEggIcon /> }
                <EmptyEggIcon />
            </PayoutIcons>;
        case "ONE_PAIR":
            return <PayoutIcons>
                {won && result && symbolMap[result[0].first] || <SolidEggIcon /> }
                {won && result && symbolMap[result[1].first] || <SolidEggIcon /> }
                <EmptyEggIcon />
                <EmptyEggIcon />
                <EmptyEggIcon />
            </PayoutIcons>;                                   
    }
}

export const symbolMap = {
    S1: <BlueGemEggIcon />,
    S2: <GreenGemEggIcon />,
    S3: <OrangeGemEggIcon />,
    S4: <PurpleGemEggIcon />,
    S5: <RedGemEggIcon />,
    S6: <TurquoiseGemEggIcon />,
    S7: <YellowGemEggIcon />
}

const rigNumbers = {
    "FIVE_OF_A_KIND": [8],
    "FOUR_OF_A_KIND": [20],
    "FULL_HOUSE": [30],
    "THREE_OF_A_KIND": [70],
    "TWO_PAIRS": [150],
    "ONE_PAIR": [300]
};

let connectionData: ICommonConnectionData;

const PrizeTable: React.FC<PanelProps> = ({
    prizeTable,
    bet,
    hideWin,
    result,
    prize,
    connectionData,
    ...props
}) => {

    return (
        <PrizeTableContainer> {
            Object.entries(prizeTable).filter( entry => entry[1] !== 0).map(([key, value]) => {
                const won = !hideWin && prize === key;
                
                return <PrizeTableLine $won={won} key={key} onClick={async () => await RigNumbers(connectionData, rigNumbers[key])}>
                    <div style={{display: "flex", gap: "8px"}}>
                        {renderPrizeType(key, won, result)} <Multiplier $won={won}>{`x${(value).toFixed(2)}`}</Multiplier>
                    </div>
                    <WinAmount>{GetCurrencySymbol() + (((value) * bet)/100).toFixed(2)}</WinAmount></PrizeTableLine>
            })
        } </PrizeTableContainer>
    )
};

interface PanelProps {
    prizeTable: {[ key: string ]: number};
    bet: number;
    hideWin: boolean;
    result?: { first: string, second: boolean }[];
    prize?: string;
    connectionData: ICommonConnectionData;
}

export default PrizeTable;