// @ts-ignore
import styled, { Keyframes, keyframes } from 'styled-components';
import BackgroundImage from "./assets/gems-background.png";
import DefaultTileFace from "./assets/tile-hidden.jpg";
import BlueTileFace from "./assets/tile-blue.jpg";
import GreenTileFace from "./assets/tile-green.jpg";
import OrangeTileFace from "./assets/tile-orange.jpg";
import PurpleTileFace from "./assets/tile-purple.jpg";
import RedTileFace from "./assets/tile-red.jpg";
import TurquoiseTileFace from "./assets/tile-turquoise.jpg";
import YellowTileFace from "./assets/tile-yellow.jpg";

import BluePillTexture from "./assets/tile-blue-line.png";
import GreenPillTexture from "./assets/tile-green-line.png";
import OrangePillTexture from "./assets/tile-orange-line.png";
import PurplePillTexture from "./assets/tile-purple-line.png";
import RedPillTexture from "./assets/tile-red-line.png";
import TurquoisePillTexture from "./assets/tile-turquoise-line.png";
import YellowPillTexture from "./assets/tile-yellow-line.png";

import LogoTexture from "./assets/logo.png";

import StrokedEggTexture from "./assets/stroked.png";
import SolidEggTexture from "./assets/solid.png";
import EmptyTexture from "./assets/empty.png";

import BlueGemTinyTexture from "./assets/gems-blue-default-tiny.png";
import GreenGemTinyTexture from "./assets/gems-green-default-tiny.png";
import OrangeGemTinyTexture from "./assets/gems-orange-default-tiny.png";
import PurpleGemTinyTexture from "./assets/gems-purple-default-tiny.png";
import RedGemTinyTexture from "./assets/gems-red-default-tiny.png";
import TurquoiseGemTinyTexture from "./assets/gems-turquoise-default-tiny.png";
import YellowGemTinyTexture from "./assets/gems-yellow-default-tiny.png";

import HenTexture from "./assets/hen.png";
import RoosterTexture from "./assets/rooster.png";

export const CharacterContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: -1;
    position: absolute;
    bottom: -50px;

    @media (max-width: 680px) {
        bottom: -71px;
    }
`;

export const HenCharacter = styled.div`
    background-image: url(${HenTexture}); 
    width: 300px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;

    @media (max-width: 575px) {
        width: 500px;
        height: 600px;
        margin-bottom: 100px;
    }
`;

export const RoosterCharacter = styled.div`
    background-image: url(${RoosterTexture});    
    width: 300px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    margin-bottom: -100px;
    transform: translateY(50px);

    @media (max-width: 575px) {
        width: 500px;
        height: 600px;

        margin-bottom: 100px;
    }
`;

const EggIcon = styled.div`
    margin-right: 10px;
    width: 16px;
    height: 16px;

    @media (orientation: portrait) {
        width: 12px;
        height: 12px;
        margin-right: 2px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
`;

export const BlueGemEggIcon = styled(EggIcon)`
    background-image: url(${BlueGemTinyTexture});
`;

export const GreenGemEggIcon = styled(EggIcon)`
    background-image: url(${GreenGemTinyTexture});
`;

export const OrangeGemEggIcon = styled(EggIcon)`
    background-image: url(${OrangeGemTinyTexture});
`;

export const PurpleGemEggIcon = styled(EggIcon)`
    background-image: url(${PurpleGemTinyTexture});
`;

export const RedGemEggIcon = styled(EggIcon)`
    background-image: url(${RedGemTinyTexture});
`;

export const TurquoiseGemEggIcon = styled(EggIcon)`
    background-image: url(${TurquoiseGemTinyTexture});
`;

export const YellowGemEggIcon = styled(EggIcon)`
    background-image: url(${YellowGemTinyTexture});
`;

export const StrokedEggIcon = styled(EggIcon)`
    background-image: url(${StrokedEggTexture});
`;

export const SolidEggIcon = styled(EggIcon)`
    background-image: url(${SolidEggTexture});
`;

export const EmptyEggIcon = styled(EggIcon)`
    background-image: url(${EmptyTexture});
`;

export const Logo = styled.div`
    background-image: url(${LogoTexture});
    height: 40px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

`;

export const Background = styled.div`
    background-image: url(${BackgroundImage});
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;
`;

export const SymbolsContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const FadeOut = keyframes`
    0% { opacity: 1 }
    100% { opacity: 0 }
`;

export const Reveal = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;


export const FlipAnimation = keyframes`
 0% { transform: rotateY(-180deg) }
 100% { transform: rotateY(0deg) }
`;

export const ReverseFlipAnimation = keyframes`
 0% { transform: rotateY(0deg) }
 100% { transform: rotateY(180deg) }
`;

export const DropInAnimation = keyframes`
    0% { transform: translateY(-300px) }
    100% { transform: translateY(0px) }
`;

export const DropOutAnimation = keyframes`
    0% { transform: translateY(0px) }
    100% { transform: translateY(300px) }
`;

export const Frame = styled.div`
    position: relative;
    overflow: hidden;
    background-color: var(--neutral-15);
    box-shadow: 0px 8px 0px 0px #0F546B;
    border-left: 2px solid var(--neutral-15);
    border-top: 2px solid var(--neutral-15);
    border-bottom: 2px solid var(--neutral-15);
    border-radius: 10px;
`;

export const EggContainer = styled.div<{$animateIn: boolean, $delay: number}>`
    top: 0px;
    position: absolute;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    animation-name: ${props => props.$animateIn ? DropInAnimation : ""};
    animation-delay: ${props => props.$delay}ms;
    animation-fill-mode: backwards;
    animation-duration: 330ms;
    width: 50px;
    aspect-ratio: 0.71;
    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: portrait) and (min-width: 375px) {
        width: 70px;
    }

    @media (orientation: portrait) and (min-width: 600px) {
        width: 116px;
    }

    @media (orientation: landscape) and (min-height: 680px) {
        width: 85px;
    }

    @media (orientation: landscape) and (min-height: 900px) {
        width: 116px;
    }
`;

export const CardContainer = styled.div<{$animateOut: boolean, $delay: number}>`
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    aspect-ratio: 0.71;
    overflow: hidden;
    animation-name: ${props => props.$animateOut ? DropOutAnimation : ""};
    animation-delay: ${props => props.$delay}ms;
    animation-fill-mode: backwards;
    animation-duration: 330ms;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: portrait) and (min-width: 375px) {
        width: 70px;
    }

    @media (orientation: portrait) and (min-width: 600px) {
        width: 116px;
    }

    @media (orientation: landscape) and (min-height: 680px) {
        width: 85px;
    }

    @media (orientation: landscape) and (min-height: 900px) {
        width: 116px;
    }
`;

const TileBackground = styled.div<{$fadeOut?: boolean, $reveal?: boolean, $delay?: number}>`
    width: 116px;
    height: 162px;
    animation-name: ${props => props.$fadeOut ? FadeOut : (props.$reveal ? Reveal : "")};
    animation-fill-mode:  ${props => props.$fadeOut ? "forwards" : (props.$reveal ? "backwards" : "")};;
    animation-duration: ${props => props.$fadeOut ? "330ms" : (props.$reveal ? "1ms" : "")};
    position: absolute;
    animation-delay: ${props => props.$delay}ms;
    background-size: contain;
`;

export const DefaultBackground = styled(TileBackground)`
    background-image: url(${DefaultTileFace});
`;

export const BlueBackground = styled(TileBackground)`
    background-image: url(${BlueTileFace});
`;

export const GreenBackground = styled(TileBackground)`
    background-image: url(${GreenTileFace});
`;

export const OrangeBackground = styled(TileBackground)`
    background-image: url(${OrangeTileFace});
`;

export const PurpleBackground = styled(TileBackground)`
    background-image: url(${PurpleTileFace});
`;

export const RedBackground = styled(TileBackground)`
    background-image: url(${RedTileFace});
`;

export const TurquoiseBackground = styled(TileBackground)`
    background-image: url(${TurquoiseTileFace});
`;

export const YellowBackground = styled(TileBackground)`
    background-image: url(${YellowTileFace});
`;

const ColouredPill = styled.div<{$delay?: number}>`
    width: 25px;
    height: 6px;
    margin-top: 13px !important;
    margin-left: 45px;
    animation-fill-mode: backwards;
    position: absolute;
    animation-duration: 330ms;
    animation-name: ${Reveal};
    animation-delay: ${ props => props.$delay}ms;
    background-position: center;
    
    @media (orientation: landscape) and (max-height: 899px) {
        margin-left: 30px;
    }

    @media (orientation: portrait) and (max-width: 599px) {
        margin-left: 25px;
    }

    @media (orientation: portrait) and (max-height: 700px) {
        margin-left: 25px;
    }

    @media (orientation: portrait) and (max-width: 374px) {
        margin-left: 14px;
    }
`;

export const BluePill = styled(ColouredPill)`
    background-image: url(${BluePillTexture});
`;

export const GreenPill = styled(ColouredPill)`
    background-image: url(${GreenPillTexture});
`;


export const OrangePill = styled(ColouredPill)`
    background-image: url(${OrangePillTexture});
`;

export const PurplePill = styled(ColouredPill)`
    background-image: url(${PurplePillTexture});
`;

export const RedPill = styled(ColouredPill)`
    background-image: url(${RedPillTexture});
`;
export const TurquoisePill = styled(ColouredPill)`
    background-image: url(${TurquoisePillTexture});
`;

export const YellowPill = styled(ColouredPill)`
    background-image: url(${YellowPillTexture});
`;

export const PrizeTableContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    

    @media (orientation: landscape) and (min-height: 618px) {
        flex-direction: column;
    }

    @media (orientation: portrait) {
        flex-direction: row;
        margin-bottom: 10px;
    }

    
`;

export const PrizeTableLine = styled.div<{ $won }>`
    display: flex;
    
    width: 100%;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    
    box-sizing: border-box;
    outline: 2px solid var(--neutral-15);
    background: ${ props => props.$won ? "var(--primary)" : "var(--neutral-15)"};

    @media (orientation: landscape) and (min-height: 618px) {
        width: 100%;
        padding: 8px 24px;
        height: 40px;
    }

    @media (orientation: portrait) {
        width: 49%;
        padding: 8px 8px;
        height: 40px;
    }

    &:nth-child(even) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &:nth-child(odd) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
`;

export const GameContainer = styled.div`
    display: flex;
    padding: 56px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    flex-shrink: 0;
    width: 100%;
`;

export const PayoutIcons = styled.div`
    display: flex;
`;

export const WinAmount = styled.div`
    font-variant-numeric: tabular-nums;
    @media (orientation: portrait) and (max-width: 700px) {
        font-size: 12px;
    }
`;

export const Multiplier = styled.div<{ $won }>`
    
    width: 42px;
    display: flex;
    
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--white, #FFF);
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    
    border-radius: 4px;
    background: var(--neutral-15, rgba(255, 255, 255, 0.15));

    @media (orientation: landscape) and (min-height: 618px) {
        font-size: 16px;
        line-height: 160%; /* 25.6px */
        padding: 1px 8px;
        margin-top: -6px;
        margin-bottom: -6px;
    }

    @media (orientation: portrait) {
        font-size: 12px;
        line-height: 160%; /* 25.6px */
        padding: 1px 2px;
        margin-top: -6px;
        margin-bottom: -6px;
    }
`;